import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasAllPerms',
})
export class HasAllPermsPipe implements PipeTransform {
  transform(user: any, perms: Array<string>): unknown {
    if (!user) return false;
    let ret = true;
    if (!Object.keys(user).includes('perms')) {
      return false;
    }
    perms.forEach((g) => {
      ret = ret && user['perms'].filter((e: string) => e === g).length > 0;
    });
    return ret;
  }
}
